// models
import { ContextRequest } from './context-request';
import { ErrorResponse } from './error';
import { PaginationRequest } from './pagination-request';
import { Article, Video } from './article';
import {
  Group,
  Case,
  Post
} from '../groups/group.model';
import { SurgeryReference } from './surgery-reference';
import { AOEvent } from './event';
import { ShortPublicProfile } from './public-profile';

export enum SearchTrackingSource {
  NEW = 'newSearch',
  RECENT = 'recentSearch',
  TOP = 'topSearch',
  SUGGESTIONS = 'suggestions',
}

export enum SearchResultItemType {
  POST = 'post',
  ARTICLE = 'article',
  VIDEO = 'video',
  GROUP = 'group',
  PEOPLE = 'people',
  SURGERY_REFERENCE = 'surgeryReference',
  EVENT = 'event',
  ALL = 'all'
}

export const SearchResultIdAll = 'all';

export interface SearchResult {
  resultName: string,
  resultRecordCount: number,
  searchResultItemType: SearchResultItemType,
  resultId: string,
  searchResultItems: Array<GroupSearch | ArticleSearch | VideoSearch | PostSearch | CaseSearch | PeopleSearch |
    SurgeryReferenceSearch | EventSearch>,
  totalCount: number
}

export interface MergedSearchResult {
  searchResultItemType: SearchResultItemType,
  searchResultItem: GroupSearch | ArticleSearch | VideoSearch | PostSearch | CaseSearch | PeopleSearch |
    SurgeryReferenceSearch | EventSearch,
}

interface GroupSearchInterface extends Group {
  hiddenByFilter?: boolean;
}

interface ArticleSearchInterface extends Article {
  hiddenByFilter?: boolean;
}

interface VideoSearchInterface extends Video {
  hiddenByFilter?: boolean;
}

interface PostSearchInterface extends Post {
  hiddenByFilter?: boolean;
}

interface CaseSearchInterface extends Case {
  hiddenByFilter?: boolean;
}

interface SurgeryReferenceSearchInterface extends SurgeryReference {
  hiddenByFilter?: boolean;
}

interface EventSearchInterface extends AOEvent {
  hiddenByFilter?: boolean;
}

interface PeopleSearchInterface extends ShortPublicProfile {
  hiddenByFilter?: boolean;
}

export type GroupSearch = GroupSearchInterface;
export type ArticleSearch = ArticleSearchInterface;
export type VideoSearch = VideoSearchInterface;
export type PostSearch = PostSearchInterface;
export type CaseSearch = CaseSearchInterface;
export type SurgeryReferenceSearch = SurgeryReferenceSearchInterface;
export type EventSearch = EventSearchInterface;
export type PeopleSearch = PeopleSearchInterface;

export interface RecentSearch {
  recentSearches: Array<string>;
  topSearches: Array<string>;
}

export interface SearchParams extends PaginationRequest, ContextRequest {
  source?: SearchTrackingSource,
  query: string,
  type: string,
  totalItemsCount?: number,
  clientVersion?: string
}

export interface SearchCluster {
  recordIds: Array<string>;
  title: string;
}

export interface AvailableResult {
  id: string;
  name: string;
  total: number;
}

export interface SearchMeta {
  availableTypes: any,
  availableResults: Array<AvailableResult>,
  clusters: Array<SearchCluster>
}

export interface SearchSuccessResponse {
  _schemaPath?: 'search';
  _schemaMethod?: 'get';
  success?: boolean;
  meta: SearchMeta;
  result: Array<SearchResult>;
}

export type SearchResponse = SearchSuccessResponse | ErrorResponse;

export interface MergedSearchSuccessResponse {
  _schemaPath?: 'search/merged';
  _schemaMethod?: 'get';
  success?: boolean;
  meta: SearchMeta;
  result: Array<MergedSearchResult>;
  totalItemsCount: number;
}

export type MergedSearchResponse = MergedSearchSuccessResponse | ErrorResponse;

export type RecentSearchesParams = ContextRequest;

export interface RecentSearchesSuccessResponse {
  _schemaPath?: 'recent/search';
  _schemaMethod?: 'get';
  success?: boolean;
  result: RecentSearch;
}

export type RecentSearchesResponse = RecentSearchesSuccessResponse | ErrorResponse;

export interface SearchSuggestListParams extends ContextRequest {
  query?: string;
}

export interface SearchSuggestListSuccessResponse {
  _schemaPath?: 'search/autosuggest';
  _schemaMethod?: 'get';
  success?: boolean;
  total?: number;
  result: Array<string>;
}

export type SearchSuggestListResponse = SearchSuggestListSuccessResponse | ErrorResponse;
