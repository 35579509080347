// models
import { GroupShort, ParentType } from './chatter-api-schema';
import { ContextRequest } from './context-request';
import { ErrorResponse } from './error';
import { PaginationRequest } from './pagination-request';
import { PersonalMediaGalleryDocument } from './personal-media-gallery';
import { PublicProfile, ShortPublicProfile } from './public-profile';
import { Approach, FractureAndTreatment, Preparation } from './surgery-reference-schema';
import { SourceCampaignForTracking } from './tracking';
import { UsersThatApplauded } from './applaud';
import { BetaRole } from './beta-role';

// type StringOrNull = string | null;

export interface ShortPublicCLinicalCase {
  _id: string;
  deleted: boolean;
  createdDate?: string;
  blurredUrl?: string;
  title: string;
  numberOfDocuments?: number;
  owner?: ShortPublicProfile;
}

export interface Keyword {
  term: string;
  isMesh?: boolean;
  isAIGenerated?: boolean;
  _id?: string;
}

export enum CreationType {
  MANUAL = 'manual',
  PPT2CASE = 'ppt2Case'
}

export interface CaseImporterBetaRole extends BetaRole {
  meta: {
    status: 'ready' | 'inProgress' | 'failed',
    numberOfCredits: number,
    usedCredits: number
  }
}

export interface GetCaseImporterBetaRoleSuccessResponse {
  success?: boolean;
  result: CaseImporterBetaRole;
}

export interface Slide {
  id: number;
  images?: Array<PersonalMediaGalleryDocument>,
  texts?: Array<string>;
}

export interface OriginalPpt {
  fileName?: string;
}

export type GetCaseImporterBetaRoleResponse = GetCaseImporterBetaRoleSuccessResponse | ErrorResponse;

export interface ClinicalCase extends ShortPublicCLinicalCase {
  ownerId?: string;
  appId?: string;
  tags: Array<string>;
  summary?: string;
  description?: string;

  aootaClassification?: string;

  preOpDescription?: string;
  preOpDocuments?: Array<PersonalMediaGalleryDocument>;

  intraOpDescription?: string;
  intraOpDocuments?: Array<PersonalMediaGalleryDocument>;

  postOpDescription?: string;
  postOpDocuments?: Array<PersonalMediaGalleryDocument>;

  clinicalFollowUpDescription?: string;
  clinicalFollowUpDocuments?: Array<PersonalMediaGalleryDocument>;

  naDocuments?: Array<PersonalMediaGalleryDocument>;

  classificationLevelOne: string;
  classificationLevelTwo?: string;

  complainMedicalCondition?: string;
  clinicalExam?: any;
  notes?: any; // TODO: replace with string type when null will be removed from db
  privateAnnotations?: any; // TODO: replace with string type when null will be removed from db

  lastModified?: string;
  sent?: boolean;

  watermarked?: boolean;

  numberOfGroupsSharedTo?: number;
  groupsSharedTo?: Array<string>;

  numberOfEventsSharedTo?: number;
  eventsSharedTo?: Array<string>;

  attachments?: Array<PersonalMediaGalleryDocument>;
  fractures?: Array<FractureAndTreatment>;
  approach?: Approach;
  preparation?: Preparation;
  owned?: boolean;
  owner?: PublicProfile;

  hasBookmarked?: boolean;
  isFeatured?: boolean;
  hasLiked?: boolean;
  numberOfLikes?: number;
  usersThatApplauded?: Array<UsersThatApplauded>;
  isInReview?: boolean;
  keywords?: Array<Keyword>;
  creationType?: CreationType;
  slides?: Array<Slide>;
  originalPpt?: OriginalPpt;
  aiExtractionRating?: number;
  media?: {
    totalImages: number;
    totalVideos: number;
    mediaAttachments: Array<PersonalMediaGalleryDocument>
  }
}

export enum CaseAccessRequestStatusType {
  PENDING = 'pending',
  GRANTED = 'granted',
  DENIED = 'denied'
}

export interface CaseAccessRequest {
  _id: string;
  deleted: boolean;
  createdDate?: string;
  status: CaseAccessRequestStatusType;
  lastModified: string;
  case: ShortPublicCLinicalCase;
  requester: ShortPublicProfile;
}

export interface CreateClinicalCaseRequest extends SourceCampaignForTracking {
  _schemaPath?: 'chatter/ionic/cases';
  _schemaMethod?: 'post';
  _schemaGetParams?: ContextRequest;
  title: string;
  classificationLevelOne: string;
  preOpDocuments?: Array<string>,
  intraOpDocuments?: Array<string>,
  postOpDocuments?: Array<string>,
  clinicalFollowUpDocuments?: Array<string>;
  watermarked?: boolean;
  attachments?: Array<string>;
  parentId?: string;
  parentType?: ParentType;
  parentTitle?: string;
}

export interface CreateClinicalCaseSuccessResponse {
  _schemaPath?: 'chatter/ionic/cases';
  _schemaMethod?: 'post';
  success?: boolean;
  result: ClinicalCase;
}

export type CreateClinicalCaseResponse = CreateClinicalCaseSuccessResponse | ErrorResponse;

export interface ClinicalCaseUpdateData {
  tags?: Array<string>;
  title?: string;
  description?: string;
  classificationLevelOne?: string;
  classificationLevelTwo?: string,
  preOpDocuments?: Array<string>,
  intraOpDocuments?: Array<string>,
  postOpDocuments?: Array<string>,
  clinicalFollowUpDocuments?: Array<string>
  naDocuments?: Array<string>;
  attachments?: Array<string>;

  preOpDescription?: string,
  intraOpDescription?: string,
  postOpDescription?: string,
  clinicalFollowUpDescription?: string,

  summary?: string,
  complainMedicalCondition?: string,
  clinicalExam?: string,
  notes?: string;
  watermarked?: boolean;
  privateAnnotations?: string;
  keywords?: Array<Keyword>;
}

export interface UpdateClinicalCaseRequest extends ClinicalCaseUpdateData {
  _schemaPath?: 'chatter/ionic/cases/<caseId>';
  _schemaMethod?: 'put';
  _schemaGetParams?: ContextRequest;
}

export interface UpdateClinicalCasesSuccessResponse {
  success?: boolean;
  result: ClinicalCase;
}

export type UpdateClinicalCasesResponse = UpdateClinicalCasesSuccessResponse | ErrorResponse;

export interface BookmarkClinicalCasesRequest extends SourceCampaignForTracking {
  _schemaPath?: 'chatter/ionic/cases/save/<caseId>';
  _schemaMethod?: 'put';
  _schemaGetParams?: ContextRequest;
  parentId?: string;
  parentType?: ParentType;
  parentTitle?: string;
}

export interface BookmarkClinicalCasesSuccessResponse {
  success?: boolean;
  result: ClinicalCase;
}

export type BookmarkClinicalCasesResponse = BookmarkClinicalCasesSuccessResponse | ErrorResponse;

export interface RemoveBookmarkClinicalCaseRequest extends SourceCampaignForTracking {
  _schemaPath?: 'chatter/ionic/cases/remove/<case-id>';
  _schemaMethod?: 'delete';
  _schemaDeleteParams?: ContextRequest;
  parentId?: string;
  parentType?: ParentType;
  parentTitle?: string;
}

export interface RemoveBookmarkClinicalCaseSuccessResponse {
  success?: boolean;
  result: ClinicalCase;
}

export type RemoveBookmarkClinicalCaseResponse = RemoveBookmarkClinicalCaseSuccessResponse | ErrorResponse;

export interface GetClinicalCaseRequest extends ContextRequest {
  _schemaPath?: 'chatter/ionic/cases/<case-id>';
  _schemaMethod?: 'get';
  _schemaGetParams?: ContextRequest;
}

export interface GetClinicalCaseSuccessResponse {
  success?: boolean;
  result: ClinicalCase;
}

export type GetClinicalCaseResponse = GetClinicalCaseSuccessResponse | ErrorResponse;

export enum CasesType {
  ALL = 'all',
  SAVED = 'saved',
  OWNED = 'owned',
  TO_REVIEW = 'to_review'
}

export interface GetClinicalCasesMeta {
  topicFilter?: Array<string>;
}

export interface GetClinicalCasesParams extends PaginationRequest, ContextRequest {
  type?: CasesType,
  topicFilter?: string
}

export interface GetClinicalCasesSuccessResponse {
  success?: boolean;
  result: Array<ClinicalCase>;
  meta: GetClinicalCasesMeta;
  totalItemsCount?: number;
}

export type GetClinicalCasesResponse = GetClinicalCasesSuccessResponse | ErrorResponse;

export interface DeleteClinicalCaseRequest extends ContextRequest {
  _schemaPath?: 'chatter/ionic/cases/<case-id>';
  _schemaMethod?: 'delete';
  _schemaDeleteParams?: ContextRequest;
}

export interface DeleteClinicalCaseSuccessResponse {
  success?: boolean;
  result: ClinicalCase;
}

export type DeleteClinicalCaseResponse = DeleteClinicalCaseSuccessResponse | ErrorResponse;

export interface CaseAccessRequestData {
  success?: boolean;
  result: Array<CaseAccessRequest>;
  totalItemsCount: number;
}

export interface GetPendingCasesRequestsRequest extends PaginationRequest, ContextRequest {
  _schemaPath?: 'chatter/ionic/cases/requests';
  _schemaMethod?: 'get';
}

export type GetPendingCasesRequestsSuccessResponse = CaseAccessRequestData;

export type GetPendingCasesRequestsResponse = GetPendingCasesRequestsSuccessResponse | ErrorResponse;

export interface CasesRequestsStatusUpdateSuccessResponse {
  _schemaPath?: 'chatter/ionic/cases/:caseId/';
  _schemaMethod?: 'put';
  success: boolean;
}

export type CasesRequestsStatusUpdateResponse = CasesRequestsStatusUpdateSuccessResponse | ErrorResponse;

export enum SharedWithType {
  CONTACT = 'contact',
  GROUP = 'group'
}

export enum SharedWithTypeFilter {
  CONTACTS = 'contacts',
  GROUPS = 'groups'
}

export interface SharedWithContact {
  type: string,
  chatId: string;
  connectionId: string;
  chatMemberPublicProfile: ShortPublicProfile
}

export interface SharedWithGroup {
  type: string,
  postId: string,
  group: GroupShort
}

export type SharedWith = SharedWithContact | SharedWithGroup;

export interface CaseSharedWithData {
  success?: boolean;
  result: Array<SharedWith>;
  totalItemsCount: number;
}

export interface GetSharedWithRequest extends PaginationRequest, ContextRequest {
  filter?: SharedWithTypeFilter;
  _schemaPath?: 'chatter/ionic/cases/sharedWith/:caseId';
  _schemaMethod?: 'get';
}

export type GetSharedWithSuccessResponse = CaseSharedWithData;

export type GetSharedWithResponse = GetSharedWithSuccessResponse | ErrorResponse;

export interface ExportClinicalCaseRequest extends ContextRequest {
  _schemaPath?: 'chatter/ionic/cases/export';
  _schemaMethod?: 'post';
}

export interface ExportClinicalCaseSuccessResponse {
  _schemaPath?: 'chatter/ionic/cases/export';
  _schemaMethod?: 'post';
  success?: boolean;
  result: ClinicalCase;
}

export type ExportClinicalCaseResponse = ExportClinicalCaseSuccessResponse | ErrorResponse;

export interface FeatureUnfeatureCaseRequest {
  _schemaPath?: '/chatter/ionic/cases/[caseId]/feature?appId=[appId]';
  _schemaMethod?: 'put';
  _schemaGetParams?: ContextRequest;
  featured: boolean;
}

export interface FeatureUnfeatureCaseSuccessResponse {
  _schemaPath?: '/chatter/ionic/cases/[caseId]/feature?appId=[appId]';
  _schemaMethod?: 'put';
  result: ClinicalCase;
}

export type FeatureUnfeatureCaseResponse = FeatureUnfeatureCaseSuccessResponse | ErrorResponse;

export interface FeaturedCasesSuccessResponse {
  _schemaPath?: '/chatter/ionic/cases/featured/?:userId?appId=[appId]&start=0&count=10';
  _schemaMethod?: 'get';
  success?: boolean;
  totalItemsCount?: number;
  result: Array<ClinicalCase>;
}

export type FeaturedCasesResponse = FeaturedCasesSuccessResponse | ErrorResponse;

export interface UpdateCaseClassificationRequest {
  _schemaPath?: '/chatter/ionic/cases/[caseId]/classification?appId=[appId]';
  _schemaMethod?: 'put';
  _schemaGetParams?: ContextRequest;
  classificationLevelOne: string;
  classificationLevelTwo: string;
}

export interface UpdateCaseClassificationSuccessResponse {
  _schemaPath?: '/chatter/ionic/cases/[caseId]/classification?appId=[appId]';
  _schemaMethod?: 'put';
  result: ClinicalCase;
}

export type UpdateCaseClassificationResponse = UpdateCaseClassificationSuccessResponse | ErrorResponse;
