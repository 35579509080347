import {Injectable} from '@angular/core';
import {BehaviorSubject, fromEvent, mapTo, merge, Observable} from 'rxjs';
import {Platform} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  private networkStatus = new BehaviorSubject<string>('unknown');

  constructor(
    private platform: Platform
  ) {
    this.platform.ready().then(() => {
      this.setInitialNetworkStatus();
      this.initializeNetworkEvents();
    });
  }

  private setInitialNetworkStatus() {
    const isOnline = navigator.onLine; // Check browser's online status
    this.networkStatus.next(isOnline ? 'online' : 'offline');
  }

  private initializeNetworkEvents() {
    const online$ = fromEvent(window, 'online').pipe(mapTo('online'));
    const offline$ = fromEvent(window, 'offline').pipe(mapTo('offline'));

    merge(online$, offline$)
      .subscribe((status) => this.networkStatus.next(status));
  }

  getNetworkStatus(): Observable<string> {
    return this.networkStatus.asObservable();
  }

  isOnline(): boolean {
    return this.networkStatus.getValue() === 'online';
  }
}
