// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container {
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
}
.card-container app-user-profile-image {
  --user-image-size: 70px;
}
.card-container .profile-info {
  padding-top: 0px;
}
.card-container .profile-info app-user-full-name::ng-deep {
  --name-text-font-size: var(--font-size-large);
  --name-text-font-weight: var(--font-weight-medium);
}
.card-container .profile-info app-user-profile-additional-info::ng-deep {
  --additional-info-font-size: var(--font-size-small);
  --additional-info-font-weight: var(--font-weight-regular);
}
.card-container .profile-info .user-country-container .user-country {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-small);
  color: var(--mi-black-color-3);
  margin: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
