// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --create-group-post-padding-top: 0px;
  --create-group-post-padding-bottom: 0px;
  --create-group-post-padding-start: 20px;
  --create-group-post-padding-end: 15px;
  --create-group-post-background-color: var(--mi-white-color);
}

.create-post-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content min-content auto min-content;
  background-color: var(--create-group-post-background-color);
}
.create-post-container .top-bar-container {
  grid-row: 1;
  grid-column: 1;
}
.create-post-container .top-bar-container app-top-bar {
  --top-bar-padding-start: var(--create-group-post-padding-start);
  --top-bar-padding-end: var(--create-group-post-padding-end);
  --top-bar-column-gap: 20px;
  --top-bar-title-text-align: center;
}
.create-post-container .top-bar-container .create-post-button {
  background: none;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-post-container .top-bar-container .create-post-button .button-text {
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-large);
  line-height: var(--font-line-height-large);
  color: var(--mi-primary-color);
}
.create-post-container .top-bar-container .create-post-button.disabled .button-text {
  color: var(--mi-color-text-lite);
}
.create-post-container .selected-parent-container {
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  -moz-column-gap: 25px;
       column-gap: 25px;
  padding: 20px;
  align-items: center;
  border-bottom: 1.5px solid var(--mi-background-color);
}
.create-post-container .selected-parent-container .parent-info-container {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: min-content auto;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
  width: 100%;
  overflow: hidden;
}
.create-post-container .selected-parent-container .parent-info-container .icon {
  grid-row: 1;
  grid-column: 1;
  color: var(--mi-primary-color);
}
.create-post-container .selected-parent-container .parent-info-container .parent-title-wrapper {
  grid-row: 1;
  grid-column: 2;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.create-post-container .selected-parent-container .parent-info-container .parent-title-wrapper .label {
  font-weight: var(--font-weight-regular);
  font-style: normal;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  background-color: var(--mi-light-blue-color);
  border-radius: 8px;
  padding: 0 10px;
  color: var(--mi-primary-color);
  white-space: nowrap;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.create-post-container .selected-parent-container .parent-image-container {
  grid-row: 1;
  grid-column: 2;
}
.create-post-container .selected-parent-container .parent-image-container app-group-image {
  --group-image-size: 30px;
}
.create-post-container .selected-parent-container .parent-image-container app-event-image {
  --event-image-size: 30px;
  --event-image-border-radius: 0px;
}
.create-post-container .select-group-container {
  grid-row: 2;
  grid-column: 1;
}
.create-post-container .select-group-container app-create-group-content-group-select {
  --create-group-content-group-select-padding-start: var(
    --create-group-post-padding-start
  );
  --create-group-content-group-select-padding-end: var(
    --create-group-post-padding-end
  );
}
.create-post-container .select-parent-container {
  grid-row: 2;
  grid-column: 1;
  padding: 20px;
  border-bottom: 3px solid var(--mi-background-color);
}
.create-post-container .create-post-input-container {
  --containers-bottom-padding: 10px;
  grid-row: 3;
  grid-column: 1;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(3, min-content) auto;
  background-color: var(--create-group-post-background-color);
  padding: 20px var(--create-group-post-padding-end) 10px var(--create-group-post-padding-start);
  overflow: auto;
}
.create-post-container .create-post-input-container .toggle-actions-container {
  display: grid;
  grid-row-gap: 8px;
  grid-row: 2;
  grid-column: 1;
  padding-bottom: var(--create-group-post-padding-end);
}
.create-post-container .create-post-input-container .clinical-case-container {
  width: 100%;
  height: 97px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto min-content;
  align-items: center;
  background-color: var(--mi-light-purple-1-color);
  border-radius: 9px;
}
.create-post-container .create-post-input-container .clinical-case-container .clinical-case-info {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-rows: 20px 29px;
  grid-template-columns: 100%;
  align-items: center;
  row-gap: 10px;
  padding: 0 15px;
  overflow: hidden;
}
.create-post-container .create-post-input-container .clinical-case-container .clinical-case-info .title-container {
  grid-row: 1;
  grid-column: 1;
}
.create-post-container .create-post-input-container .clinical-case-container .clinical-case-info .title-container .title {
  margin: 0;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
  color: var(--mi-secondary-color-2);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.create-post-container .create-post-input-container .clinical-case-container .clinical-case-info .tags-container {
  grid-row: 2;
  grid-column: 1;
  display: flex;
  -moz-column-gap: 10px;
       column-gap: 10px;
  overflow: hidden;
}
.create-post-container .create-post-input-container .clinical-case-container .clinical-case-info .tags-container .primary-topic-container,
.create-post-container .create-post-input-container .clinical-case-container .clinical-case-info .tags-container .secondary-topic-container {
  max-width: 50%;
  overflow: hidden;
}
.create-post-container .create-post-input-container .clinical-case-container .clinical-case-info .tags-container .primary-topic-container app-case-classification-chip,
.create-post-container .create-post-input-container .clinical-case-container .clinical-case-info .tags-container .secondary-topic-container app-case-classification-chip {
  display: inline-block;
  --case-classification-text-color: var(--mi-grey-color-13);
  --case-classification-text-size: var(--font-size-small);
  width: 100%;
}
.create-post-container .create-post-input-container .clinical-case-container .clinical-case-info .tags-container .primary-topic-container app-case-classification-chip.secondary,
.create-post-container .create-post-input-container .clinical-case-container .clinical-case-info .tags-container .secondary-topic-container app-case-classification-chip.secondary {
  --case-classification-chip-selected-background-color: var(
    --mi-light-green-color
  );
}
.create-post-container .create-post-input-container .clinical-case-container .clinical-case-images {
  grid-row: 1;
  grid-column: 2;
}
.create-post-container .create-post-input-container .clinical-case-container .clinical-case-images app-image-attachments-container {
  --image-attachments-container-padding-top: 0px;
  --image-attachments-container-padding-bottom: 0px;
  --image-attachments-container-padding-start: 0px;
  --image-attachments-container-padding-end: 0px;
  --image-attachments-container-width: 97px;
  --image-attachments-container-height: 97px;
  --image-attachments-first-image-height: 97px;
}
.create-post-container .create-post-input-container app-rich-text-document-editor {
  --rich-text-document-editor-padding-top: 0px;
  --rich-text-document-editor-padding-bottom: 0px;
  --rich-text-document-editor-padding-start: 0px;
  --rich-text-document-editor-padding-end: 0px;
  grid-row: 2;
  grid-column: 1;
  padding-bottom: var(--containers-bottom-padding);
}
.create-post-container .create-post-input-container .attachments-container {
  grid-row: 3;
  grid-column: 1;
  padding-bottom: var(--containers-bottom-padding);
}
.create-post-container .create-post-input-container .attachments-container app-image-attachments-container {
  --image-attachments-container-padding-top: 0px;
  --image-attachments-container-padding-bottom: 0px;
  --image-attachments-container-padding-start: 0px;
  --image-attachments-container-padding-end: 0px;
}
.create-post-container .bottom-bar-container {
  grid-row: 4;
  grid-column: 1;
  height: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
