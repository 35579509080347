import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ImageAttachment, isVideo } from 'src/app/services/attachments.model';

// models
import { ImageType } from './image-attachments-container.model';

@Component({
  selector: 'app-image-attachments-container',
  templateUrl: './image-attachments-container.component.html',
  styleUrls: ['./image-attachments-container.component.scss'],
})
export class ImageAttachmentsContainerComponent implements OnChanges {

  @Input() images: Array<ImageAttachment>;
  @Input() displaySingleImageOnly: boolean;
  @Input() showRemoveButton: boolean;
  @Input() showEditButton: boolean;
  @Input() preventClick: boolean;
  @Input() showCountBadge = false; // works only for displaySingleImageOnly
  @Input() showUploadPlaceholder = false;
  @Input() preventOneImageIncreasedSize = false;
  @Input() blurredImage = false;
  @Input() totalImages?: number;
  @Input() totalVideos?: number;
  @Output() openImageLink: EventEmitter<string> = new EventEmitter();
  @Output() removeClicked: EventEmitter<any> = new EventEmitter();
  @Output() openGallery: EventEmitter<Array<ImageAttachment>> = new EventEmitter<Array<ImageAttachment>>();

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.images) {
      this.images = changes.images.currentValue.map(img => {
        if (!img.type) {
          img.type = isVideo((img as any)?.mimeType) ? ImageType.VIDEO : ImageType.IMAGE;
        }
        return img;
      });
    }
  }

  onRemoveButtonClick(): void {
    this.removeClicked.emit();
  }

  onEditButtonClick(): void {
    // NYI
  }

  openGalleryModal(): void {
    this.openGallery.emit(this.images);
  }

  get imagesCount(): number {
    return this.totalImages ?? this._getImagesCount(ImageType.IMAGE);
  }

  get videosCount(): number {
    return this.totalVideos ?? this._getImagesCount(ImageType.VIDEO);
  }

  get imagesCountMinus1(): number {
    const visibleCount = this._getImagesCount(ImageType.IMAGE, 1);
    return this.imagesCount - visibleCount;
  }

  get videosCountMinus1(): number {
    const visibleCount = this._getImagesCount(ImageType.VIDEO, 1);
    return this.videosCount - visibleCount;
  }

  get imagesCountMinus5(): number {
    const visibleCount = this._getImagesCount(ImageType.IMAGE, 5);
    return this.imagesCount - visibleCount;
  }

  get videosCountMinus5(): number {
    const visibleCount = this._getImagesCount(ImageType.VIDEO, 5);
    return this.videosCount - visibleCount;
  }

  _getImagesCount(imageType: ImageType, numberToCheck = -1): number {
    if (numberToCheck < 0) { // all
      numberToCheck = this.images.length;
    }
    else if (numberToCheck === 0) {
      return 0;
    }
    return this.images.slice(0, numberToCheck).filter(item => {

      if (!item.type) {
        item.type = isVideo((item as any)?.mimeType) ? ImageType.VIDEO : ImageType.IMAGE;
      }

      return item.type === imageType;
    }).length;
  }

  moreThenFiveImagesContainerClicked(): void {

    if (this.preventClick) {
      return;
    }

    this.openGalleryModal();
  }

  get totalMediaAttachments(): number {
    return this.imagesCount + this.videosCount;
  }

}
