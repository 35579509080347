import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-article-image',
  templateUrl: './article-image.component.html',
  styleUrls: ['./article-image.component.scss'],
})
export class ArticleImageComponent {
  @Input() imageUrl: string;
  @Input() bannerText?: string;
  showDefaultArticleImage: boolean;

  get typeStyle(): string {
    return this.isVideo ? 'video-style' : 'article-style';
  }

  get isVideo(): boolean {
    return this.bannerText?.toLowerCase() === 'video' || this.bannerText?.toLowerCase() === 'ao-video';
  }

  get defaultArticleImageSrc(): string {
    return this.isVideo ? 'assets/images/default-videos-image.svg' : 'assets/images/default-articles-image.svg';
  }
}
