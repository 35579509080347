import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  BrowserModule,
  HammerModule,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { Chooser } from '@awesome-cordova-plugins/chooser/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { Zip } from '@awesome-cordova-plugins/zip/ngx';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { MIDialogsModule } from './dialogs/dialogs.module';
import { MIComponentsModule } from './components/components.module';

import { ResourcesUpdateService } from './services/resources-update/resources-update.service';
import {
  StorageService,
  LOCAL_STORAGE_SERVICE,
  SESSION_STORAGE_SERVICE,
} from './services/storage/storage.service';
import {
  SchemaValidatorService,
  SCHEMA_VALIDATOR_SERVICE_CONFIG,
} from './services/schema-validator.service';

import { AdminStateService } from './services/admin-state/admin-state.service';
import { AUTH_LOGIC_SERVICE } from './services/auth/logic/auth-logic.service.interface';
import { AuthLogicService } from './services/auth/logic/auth-logic.service';
import { LoadingInterceptor } from './services/loadingInterceptor/loadingInterceptor';

// import { AuthDialogComponent } from './dialogs/auth-dialog/auth-dialog.component';
// import { AuthWelcomePage } from './dialogs/auth/auth-welcome/auth-welcome.page';

import { AppTranslationModule } from './modules/app-translation.module';
import { InfoSheetModule } from './modules/info-sheet/info-sheet.module';
import { STORAGE_MODE } from './services/storage/storage.model';

import { environment } from '../environments/environment';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { CookieConsentService } from './services/cookie-consent/cookie-consent.service';
import { PushNotificationsService } from './services/deep-links/push-notifications.service';
import { NativeSettingsService } from './services/deep-links/native-settings.service';
import { ResourcesUpdateUIService } from './services/resources-update/resources-update-ui.service';
import { ExitService } from './services/resources-update/exit.service';
import { IonicWebViewWrapperService } from './services/resources-update/ionic-webview-wrapper.service';

import appConfig from '../config/config';
import { packageConfig } from '../config/package.json.config';
import { IonicGestureConfig } from './services/ionic-gesture-config';
import { SHARING_SERVICE } from './services/sharing/sharing.service.interface';
import { SharingDeviceService } from './services/sharing/sharing.device.service';
import { SharingBrowserService } from './services/sharing/sharing.browser.service';
import { ImageSharingDeviceService } from './services/sharing/image-sharing.device.service';
import { UI_UTILS_SERVICE } from './services/utils/ui-utils.service.interface';
import { UiUtilsService } from './services/utils/ui-utils.service';
import { FulltextArticleService } from './services/article/fulltext-article.service';
import { JOIN_MYAO_OBJECT_SERVICE } from './services/join-myao/join-myao-object-service/join-myao-object.service.interface';
import { JoinMyAOObjectService } from './services/join-myao/join-myao-object-service/join-myao-object.service';
import {
  JOIN_MYAO_OBJECT_CASE_SERVICE,
  JOIN_MYAO_OBJECT_GROUP_SERVICE,
  JOIN_MYAO_OBJECT_PROFILE_SERVICE,
} from './services/join-myao/join-myao-object-x-service/join-myao-object-x.service.interface';
import { JoinMyAOObjectCaseService } from './services/join-myao/join-myao-object-x-service/join-myao-object-case.service';
import { JoinMyAOObjectGroupService } from './services/join-myao/join-myao-object-x-service/join-myao-object-group.service';
import { JoinMyAOObjectProfileService } from './services/join-myao/join-myao-object-x-service/join-myao-object-profile.service';
import { ImageEditorModule } from './modules/image-editor/image-editor.module';
import { isMobilePlatform } from './services/utils/utils';
import { AppInstallPromptService } from './services/app-install-prompt.service';
import { ContextDialogsUIService } from './services/dialogs/context-dialogs.ui.service';
import { CONTEXT_DIALOGS_UI } from './services/dialogs/dialogs.ui.interface';
import { SURGERY_REFERENCE_UI_SERVICE } from './services/surgery-reference/surgery-reference-ui.service.interface';
import { SurgeryReferenceUIService } from './services/surgery-reference/surgery-reference-ui.service';
import { SURGERY_REFERENCE_SERVICE } from './services/surgery-reference/surgery-reference.service.interface';
import { SurgeryReferenceService } from './services/surgery-reference/surgery-reference.service';
import { CLIENT_CONFIG_SERVICE } from './services/config/client-config.service.interface';
import { ClientConfigurationService } from './services/config/client-config.service';
import { CONTEXT_SERVICE } from './services/context/context.model';
import { ContextServiceImpl } from './services/context/context.service';
import { TrackingServiceImpl } from './services/tracking/tracking.service';
import { TRACKING_SERVICE } from './services/tracking/tracking.model';
import { CONTEXT_SWITCH_SERVICE } from './services/context/context-switch.model';
import { ContextSwitchServiceImpl } from './services/context/context-switch.service';
import { FilePath } from '@awesome-cordova-plugins/file-path/ngx';
import { OrcIdAuthService } from 'src/app/services/auth/orcid/orcid-auth.service';
// import { CreateContentStrategy, CreateEventContentStrategy, CreateGroupContentStrategy } from './services/create-content/create-content';
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { states } from './state';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import {NoInternetConnectionPage} from './pages/no-internet-connection/no-internet-connection.page';

const moduleImports: any[] = [
  BrowserModule,
  HammerModule,
  IonicModule.forRoot(),
  HttpClientModule,
  RouterModule,
  AppRoutingModule,
  AppTranslationModule,
  ReactiveFormsModule,
  MIDialogsModule,
  ImageEditorModule,
  MIComponentsModule,
  InfoSheetModule,
  BrowserAnimationsModule,
  PdfViewerModule,
  NgxsModule.forRoot(states),
  NgxsLoggerPluginModule.forRoot({
    disabled: environment.production
  }),
  NgxsReduxDevtoolsPluginModule.forRoot(),
  NgxsResetPluginModule.forRoot(),
];

if (environment.pwa) {
  moduleImports.push(
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    })
  );
}

if (appConfig.cookieConsentDomain) {
  moduleImports.push(
    NgcCookieConsentModule.forRoot(CookieConsentService.moduleConfig)
  );
}

if (appConfig.googleAnalyticsId) {
  moduleImports.push(
    GoogleTagManagerModule.forRoot({
      id: appConfig.googleAnalyticsId,
    })
  );
}

const moduleProviders: any[] = [
  Clipboard,
  StatusBar,
  SplashScreen,
  { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  {
    provide: LOCAL_STORAGE_SERVICE,
    useValue: new StorageService({ mode: STORAGE_MODE.localstorage }),
  },
  {
    provide: SESSION_STORAGE_SERVICE,
    useValue: new StorageService({ mode: STORAGE_MODE.localstorage }),
  },
  {
    provide: SCHEMA_VALIDATOR_SERVICE_CONFIG,
    useValue: {
      schemasLocation: 'assets/json-schema/',
      enabled: packageConfig.schemaValidationEnabled,
    },
  },
  {
    provide: CONTEXT_SERVICE,
    useClass: ContextServiceImpl,
  },
  {
    provide: CONTEXT_SWITCH_SERVICE,
    useClass: ContextSwitchServiceImpl,
  },
  {
    provide: TRACKING_SERVICE,
    useClass: TrackingServiceImpl,
  },
  SchemaValidatorService,
  { provide: AUTH_LOGIC_SERVICE, useClass: AuthLogicService },
  AdminStateService,
  ...AuthLogicService.requiredAuthServices,
  { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
  Camera,
  Chooser,
  File,
  Device,
  { provide: HAMMER_GESTURE_CONFIG, useClass: IonicGestureConfig },
  { provide: UI_UTILS_SERVICE, useClass: UiUtilsService },
  { provide: JOIN_MYAO_OBJECT_SERVICE, useClass: JoinMyAOObjectService },
  {
    provide: JOIN_MYAO_OBJECT_CASE_SERVICE,
    useClass: JoinMyAOObjectCaseService,
  },
  {
    provide: JOIN_MYAO_OBJECT_GROUP_SERVICE,
    useClass: JoinMyAOObjectGroupService,
  },
  {
    provide: JOIN_MYAO_OBJECT_PROFILE_SERVICE,
    useClass: JoinMyAOObjectProfileService,
  },
  { provide: CONTEXT_DIALOGS_UI, useClass: ContextDialogsUIService },
  {
    provide: SURGERY_REFERENCE_UI_SERVICE,
    useClass: SurgeryReferenceUIService,
  },
  { provide: SURGERY_REFERENCE_SERVICE, useClass: SurgeryReferenceService },
  { provide: CLIENT_CONFIG_SERVICE, useClass: ClientConfigurationService },
  FilePath,
  ...OrcIdAuthService.requiredAuthServices,
];

if (appConfig.authenticationAO) {
  moduleProviders.push(FulltextArticleService);
}

if (appConfig.cookieConsentDomain) {
  moduleProviders.push(...CookieConsentService.cookieServices);
}

if (!appConfig.appUpdateDisabled) {
  moduleProviders.push(
    ...[
      HTTP,
      Zip,
      IonicWebViewWrapperService,
      ExitService,
      ResourcesUpdateService,
      ResourcesUpdateUIService,
    ]
  );
}

if (isMobilePlatform(appConfig.platform)) {
  moduleProviders.push(
    ...[
      PushNotificationsService,
      OpenNativeSettings,
      NativeSettingsService,
      Deeplinks,
      SocialSharing,
      { provide: SHARING_SERVICE, useClass: SharingDeviceService },
      ImageSharingDeviceService,
    ]
  );
} else {
  moduleProviders.push(
    ...[
      AppInstallPromptService,
      { provide: SHARING_SERVICE, useClass: SharingBrowserService },
    ]
  );
}

@NgModule({
  declarations: [AppComponent, NoInternetConnectionPage],
  imports: moduleImports,
  providers: moduleProviders,
  bootstrap: [AppComponent],
})
export class AppModule { }
